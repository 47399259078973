



const heroSliderData = [
    {
        title_1: "The Ultimate Cape Town Bucket List:  ",
        title_2: "Must-Do Experiences ",
        title_3: "for Your 7 to 14 Day Stay ",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        class:'center'
    },
    
]

export default heroSliderData;